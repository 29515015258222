<template>
  <div
    id="word"
    :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
      isApp ? systemBarHeight : 0
    }px`"
    class="none-select"
    v-scroll:#word-table-scroll="onScroll"
  >
    <word-table-app-header
      :key="needRefresh"
      ref="detailAppHead"
      @updateWord="updateWord"
      @goBack="goBack"
      :isLoading="isLoading"
      :totalWords="totalWords"
      :initWords="searchWord"
    >
    </word-table-app-header>
    <div
      id="word-table-scroll"
      class="word-table-scroll show-scroll"
      :style="`height:${
        isApp ? screenHeight - systemBarHeight - 56 : screenHeight - 56
      }px;`"
    >
      <van-pull-refresh
        style="min-height: 100%"
        v-model="isRefreshing"
        @refresh="onRefresh"
      >
        <div class="word-content">
          <!-- item.filter(
                  (data) =>
                    !searchWord ||
                    data.word.toLowerCase().includes(searchWord.toLowerCase())
                ) -->
          <v-fade-transition group>
            <word-panel
              ref="wordPanel"
              :searchWord="searchWord"
              v-for="(item, key) in wordTable"
              :key="key"
              :date="key"
              :wordList="item.filter((data) => true)"
            ></word-panel>
          </v-fade-transition>
          <error-page
            :offLine="offLine"
            @doRefresh="onRefresh"
            v-if="
              !isLoading && isEmpty(this.wordTable) && isEmpty(this.searchWord)
            "
          ></error-page>
          <app-load-more
            class="mt-5"
            v-show="!isEmpty(this.wordTable) || !isEmpty(this.searchWord)"
            :isLoading="isLoading"
            :hasMore="hasNext"
            @loadMore="loadData(true)"
          ></app-load-more>
          <div class="footer-takeplace"></div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import $api from "@/api/api";
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import { isEmpty } from "@/utils/common";
import WordTableAppHeader from "@/views/wordTable/components/wordTableAppHeader";
import AppLoadMore from "@/components/app/appLoadMore";

export default {
  name: "wordTableIndex",
  mixins: [setSystemColor, buttonVibrate],
  components: {
    AppLoadMore,
    WordPanel: () => import("@/views/wordTable/components/wordPanel"),
    ErrorPage: () => import("@/views/wordTable/components/errorPage"),
    WordTableAppHeader,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      wordSearchHistory: "wordSearchHistory",
      screenHeight: "screenHeight",
    }),
  },
  data: () => ({
    offLine: false,
    isLoading: true,
    isRefreshing: false,
    firstCreat: true,
    totalWords: null,
    totalPages: 0,
    hasNext: false,
    searchWord: "",
    wordTable: {},
    lastScrollTop: 0,
    isLoadingMore: false,
    currentPage: 1,
    needRefresh: false,
  }),
  methods: {
    isEmpty,
    goBack() {},
    onRefresh() {
      // 调接口
      this.searchWord = "";
      this.$refs.detailAppHead.unfocus();
      this.loadData();
    },
    updateWord(word) {
      this.searchWord = word;
      this.loadData();
    },
    loadData(loadMore) {
      this.offLine = false;
      this.isLoading = true;
      if (loadMore) {
        if (!this.hasNext) {
          this.isLoading = false;
          return this.$toast("已经加载到最后啦!");
        }
      } else {
        // 全新加载
        this.wordTable = {};
        this.currentPage = 1;
        this.isLoadingMore = false;
        this.totalPages = 0;
        this.hasNext = false;
      }
      let getData = {
        limit: 20,
        page: this.currentPage,
      };
      if (!isEmpty(this.searchWord)) {
        getData.word = this.searchWord;
      }
      $api
        .getMyWords(getData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.currentPage += 1;
            let result = res.data.result;
            if (result) {
              result.forEach((value) => {
                if (this.wordTable[value.createTime]) {
                  // 已有
                  this.wordTable[value.createTime].push(value);
                } else {
                  this.wordTable[value.createTime] = [];
                  this.wordTable[value.createTime].push(value);
                }
              });
            }
            this.totalWords = res.data.totalRecords;
            this.totalPages = res.data.totalPages;
            this.hasNext = res.data.hasNext;
            // this.
          } else {
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          this.offLine = true;
          console.log(err);
        })
        .finally(() => {
          // console.log(this.wordTable);
          this.$forceUpdate();
          this.isLoading = false;
          this.isLoadingMore = false;
          this.isRefreshing = false;
        });
    },
    //页面滚动判断方法
    onScroll(e) {
      if (!e || !e.target || !e.target.scrollTop) {
        return;
      }
      setTimeout(() => {
        //scrollTop被卷去的高度 ；offsetHeight可视区域高度；scrollHeight整个元素的总高度包括不可见区域
        let scrollHeight = e.target.scrollHeight;
        let scrollTop = e.target.scrollTop;
        let offsetHeight = e.target.offsetHeight;
        this.$refs.detailAppHead.offsetTop = scrollTop;
        if (
          scrollHeight - offsetHeight - scrollTop < 150 &&
          !this.isLoading &&
          !this.isLoadingMore
        ) {
          console.log("loadmore...");
          if (this.hasNext) {
            this.isLoadingMore = true;
            this.loadData(true);
          }
        }
      }, 50);
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#eaf5f5");
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
    },
  },
  created() {
    console.info("wordtable组件被创建");
    this.init();
    this.loadData();
  },
  mounted() {
    console.info("wordtable组件被挂载");
  },
  activated() {
    console.info("wordtable组件被激活");
    if (this.firstCreat) {
      // 第一次创建
      this.firstCreat = false;
    } else {
      if (this.needRefresh) {
        // 返回键退出的
        this.needRefresh = false;
        this.loadData();
      } else {
        console.log("wordtable组件从缓存加载");
        this.wordTable = this.$store.getters.wordTable;
        // console.log(this.wordTable);
        this.$forceUpdate();
        // document
        //   .querySelector("#word-table-scroll")
        //   .scrollTo(0, this.lastScrollTop);
        this.$vuetify.goTo(this.lastScrollTop, {
          container: "#word-table-scroll",
          duration: 800,
          easing: "easeInOutCubic",
        });
      }
      this.init();
    }
  },
  deactivated() {
    console.info("wordtable组件被暂停");
    this.lastScrollTop = document.querySelector("#word-table-scroll").scrollTop;
    this.$store.dispatch("setWordTable", this.wordTable);
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (from.name === "wordTable") {
        return;
      }
      if (from.name !== "wordDetail") {
        this.searchWord = "";
        this.needRefresh = true;
      }
      console.log("needRefresh：", this.needRefresh);
    },
  },
};
</script>

<style lang="scss" scoped>
#word {
  height: 100%;
  overflow: hidden;

  .word-table-scroll {
    overflow-y: auto;

    .word-content {
      width: calc(100% - 1rem);
      margin: 0 auto;
      min-height: 90vh;
      padding: 0 0.5rem;
      word-wrap: break-word;

      ::v-deep .v-subheader {
        padding: 0 6px 0 6px;
      }

      ::v-deep .v-expansion-panel-header__icon .v-icon.v-icon {
        font-size: 10px;
      }

      .footer-takeplace {
        width: 100%;
        height: 3.5rem;
      }
    }
  }
}
</style>
