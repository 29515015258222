<template>
  <v-app-bar
    :color="appThemeConfig.headColor"
    :dark="appThemeConfig.dark"
    elevate-on-scroll
    shrink-on-scroll
    prominent
    max-height="110"
    scroll-target="#word-table-scroll"
    class="app-bar rounded-b-lg"
    :src="require('@/assets/background/colorful/bg-star.jpg')"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to bottom right, rgba(250,250,250,.6), rgba(255,255,255,1)"
      >
      </v-img>
    </template>
    <div class="left-info">
      <v-btn @touchstart="zzzMid" icon @click="goIndex">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span style="color: #505050; font-size: 16px; padding-left: 12px">
        我的生词本
      </span>
    </div>
    <v-spacer></v-spacer>
    <v-chip
      style="margin-top: 13px"
      small
      color="indigo lighten-2"
      text-color="white"
    >
      {{ totalWords }} 词
    </v-chip>

    <v-slide-y-transition>
      <v-text-field
        v-show="this.offsetTop < 30"
        clearable
        clear-icon="mdi-close"
        @click:clear="zzzMid"
        filled
        rounded
        dense
        hide-details
        label="筛选单词本中的单词"
        placeholder="输入单词"
        prepend-inner-icon="mdi-magnify"
        v-model="searchWord"
        class="input-search rounded-lg"
        autocomplete="off"
        ref="searchInput"
        :style="`opacity: ${1 - offsetTop / 35}`"
      >
      </v-text-field>
    </v-slide-y-transition>
    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      absolute
      top
      color="deep-purple accent-1"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "wordTableAppHeader",
  mixins: [buttonVibrate],
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    totalWords: {
      type: Number,
      default: null,
    },
    initWords: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
    }),
  },

  watch: {
    searchWord(newVal) {
      if (newVal === null) {
        this.searchWord = "";
        return;
      }
      this.updateWord();
    },
  },
  data: () => ({
    searchWord: "",
    offsetTop: 0,
    inputTimer: null,
  }),
  methods: {
    unfocus() {
      this.searchWord = "";
      this.$refs.searchInput.blur();
    },
    updateWord() {
      // console.log("updateword");
      if (
        this.searchWord.trim() === "" ||
        /^[A-Za-z ]+$/g.test(this.searchWord)
      ) {
        clearTimeout(this.inputTimer); //防抖
        this.inputTimer = setTimeout(() => {
          this.$emit("updateWord", this.searchWord);
        }, 200);
      }
    },
    goBack() {
      this.$emit("goBack");
      this.$router.back();
    },
    goIndex() {
      this.zzzShort();
      this.$router.replace("/");
    },
  },
  created() {
    this.searchWord = this.initWords;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  min-height: calc(28px + 0.85rem) !important;
  padding-left: 16px !important;
}
::v-deep .v-icon {
  font-size: 1rem;
}
::v-deep .v-label {
  font-size: 0.85rem;
  letter-spacing: 0.15rem;
}
::v-deep .v-input--dense .v-label {
  top: 14.5px;
}

.app-bar {
  z-index: 3;
  .left-info {
    display: flex;
    align-items: center;
    margin-left: -12px;
    padding-left: 0;
    height: 50px;
    min-width: 60%;
    vertical-align: middle;
  }
  .input-search {
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 27px;
    ::v-deep .fa-times {
      font-size: 0.8rem;
      color: #7a7a7a;
    }
    ::v-deep .v-input__prepend-inner {
      margin-right: 4px;
    }
  }
}
</style>
